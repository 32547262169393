@tailwind base;
@tailwind components;
@tailwind utilities;
@import "fonts";

body {
  font-family: 'Rubik-Regular', serif;
}

input:focus {
  outline: none!important;
}
/* custom scrollbar */
*::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.563rem;
}
*::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 0.313rem;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;

}
*::-webkit-scrollbar-track {
  // background: #eeeeee;
}
