%transition {
  transition: 0.3s ease-in-out;
}

.nav {
  @apply
  bg-main
  flex
  flex-col
  items-center
  justify-between;
  min-width: 9rem;
}

%navItem {
  @apply
  relative
  flex
  flex-col
  justify-center
  items-center
  p-4
  px-5
  w-full
  text-dawn-gray
  text-base
  cursor-pointer;
}

.navItemDefault {
  @apply
  bg-main
  opacity-80
  hover:bg-opacity-50
  hover:bg-pampas;
  @extend %navItem, %transition;
}

.navItemActive {
  @apply
  font-medium-rubik
  text-dawn-gray
  bg-pampas;
  @extend %navItem;
}
