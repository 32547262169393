@font-face {
  font-family: 'Rubik-Medium';
  src: url('../assets/fonts/Rubik-Medium.eot');
  src: local('Rubik Medium'), local('Rubik-Medium'),
  url('../assets/fonts/Rubik-Medium.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Rubik-Medium.woff') format('woff'),
  url('../assets/fonts/Rubik-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik-Regular';
  src: url('../assets/fonts/Rubik-Regular.eot');
  src: local('Rubik Regular'), local('Rubik-Regular'),
  url('../assets/fonts/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Rubik-Regular.woff') format('woff'),
  url('../assets/fonts/Rubik-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik-Light';
  src: url('../assets/fonts/Rubik-Light.eot');
  src: local('Rubik Light'), local('Rubik-Light'),
  url('../assets/fonts/Rubik-Light.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Rubik-Light.woff') format('woff'),
  url('../assets/fonts/Rubik-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik-Bold';
  src: url('../assets/fonts/Rubik-Bold.eot');
  src: local('Rubik Bold'), local('Rubik-Bold'),
  url('../assets/fonts/Rubik-Bold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Rubik-Bold.woff') format('woff'),
  url('../assets/fonts/Rubik-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
