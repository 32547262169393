.nav {
    @apply
    bg-main
    flex
    flex-col
    items-center
    justify-between;
    min-width: 9rem;
}

.viewLayout {
    width: calc(100vw - 9rem);
}
