.textarea {
  @apply
  placeholder-gray-light
  bg-silver-light
  w-full
  h-full
  pl-6
  pr-12
  py-5
  rounded
  resize-none
  focus:outline-none;
}

.default {
  @apply
  bg-silver-light
  text-dawn-gray;
}

.note {
  @apply
  text-dark-gray
  font-light
  text-lg
  bg-milk;

  &::placeholder {
    color: #ADADAD;
  }
}

.note-video {
  @apply
  bg-main
  font-light-rubik
  font-semibold
  py-8
  px-5;

  &::placeholder {
    color: #C2C2C2;
  }
}
